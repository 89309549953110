const loadFeaturesBlock = (editor) => {
    editor.BlockManager.add('Features Block', {
      label: 'Features Block',
      content: `
        <section class="features-block" style="padding: 50px 20px; background: #fff;">
          <div style="text-align: center; margin-bottom: 30px;">
            <h3 style="font-size: 2em;">Our Features</h3>
            <p style="color: #777;">What makes us different</p>
          </div>
          <div style="display: flex; justify-content: space-around;">
            <div style="width: 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h4 style="font-size: 1.5em;">Feature 1</h4>
              <p style="color: #777;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim.</p>
            </div>
            <div style="width: 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h4 style="font-size: 1.5em;">Feature 2</h4>
              <p style="color: #777;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim.</p>
            </div>
            <div style="width: 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h4 style="font-size: 1.5em;">Feature 3</h4>
              <p style="color: #777;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim.</p>
            </div>
          </div>
        </section>
      `,
     
    });
  };
  
  export default loadFeaturesBlock;
  