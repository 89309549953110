const loadQuoteBlock = (editor) => {
    editor.BlockManager.add('quote-block', {
      label: 'Styled Quote',
      content: `
        <blockquote style="border-left: 4px solid #63b3ed; padding-left: 20px; margin: 40px 20px; font-size: 1.5em; color: #2d3748; font-style: italic;">
          "Esta es una cita destacada del artículo que merece especial atención."
        </blockquote>
      `,
      category: 'Blog',
    });
  };
  
  export default loadQuoteBlock;
  