const loadQuoteBlock = (editor) => {
    editor.BlockManager.add('quote-block', {
      label: 'Quote',
      content: `
        <section class="quote-section">
          <div class="quote-container">
            <blockquote class="quote-text">"El éxito no es la clave de la felicidad. La felicidad es la clave del éxito."</blockquote>
            <cite class="quote-author">- Albert Schweitzer</cite>
          </div>
        </section>
        <style>
          .quote-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .quote-container {
            max-width: 800px;
            margin: 0 auto;
            font-style: italic;
          }
          .quote-text {
            font-size: 24px;
            color: #333;
            margin-bottom: 10px;
          }
          .quote-author {
            font-size: 18px;
            color: #555;
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadQuoteBlock;
  