import React, { useState } from 'react';
import NewLandingPage from '../components/Newsletter/MetadataForm';
import GrapesEditor from '../components/Newsletter/GrapesEditor';





function NewsletterCreator() {
  

  return (
    <>

 

    <GrapesEditor/>



    </>
  );
}

export default NewsletterCreator;
