import React, { useState } from 'react';

import MetadataForm from '../components/LandingPage/MetadataForm';




function LandingPagePageMeta() {
  

  return (
    <>

 

    <MetadataForm/>



    </>
  );
}

export default LandingPagePageMeta;
