const loadContactBlock = (editor) => {
    editor.BlockManager.add('contact-block', {
      label: 'Contact Us',
      content: `
        <section class="contact-section">
          <div class="contact-container">
            <h2 class="contact-title">Comunícate con Nosotros</h2>
            <p class="contact-description">Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos.</p>
            <form class="contact-form">
              <input type="text" class="contact-input" placeholder="Tu Nombre" required>
              <input type="email" class="contact-input" placeholder="Tu Email" required>
              <textarea class="contact-textarea" placeholder="Tu Mensaje" required></textarea>
              <button type="submit" class="contact-button">Enviar</button>
            </form>
          </div>
        </section>
        <style>
          .contact-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .contact-container {
            max-width: 600px;
            margin: 0 auto;
          }
          .contact-title {
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #333;
          }
          .contact-description {
            font-size: 18px;
            margin-bottom: 30px;
            color: #666;
          }
          .contact-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
          .contact-input, .contact-textarea {
            padding: 15px;
            font-size: 16px;
            border: 1px solid #ddd;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;
          }
          .contact-button {
            background-color: #007bff;
            color: white;
            padding: 15px;
            font-size: 18px;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
            transition: background-color 0.3s ease;
          }
          .contact-button:hover {
            background-color: #0056b3;
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadContactBlock;
  