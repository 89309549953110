const loadIframeBlock = (editor) => {
    editor.BlockManager.add('Formulario airtable', {
      label: 'Formulario airtable',
      content: `
       <iframe class="airtable-embed" src="" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
      `,
     
    });
  };
  
  export default loadIframeBlock;
  