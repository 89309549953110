import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LandingPageList() {
  const [landing, setLanding] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false); // Desactiva la carga después de 15 segundos
    }, 15000);

    fetch('https://modelo-de-negocios-backend-180g.onrender.com/newsletter')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          setLanding(data);
        } else {
          console.error("Unexpected data format:", data);
          setLanding([]); // Default to empty array if data format is unexpected
        }
      })
      .catch(error => {
        console.log("Error Landing Pages: ", error);
        setLanding([]); // Set to empty array on error
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);  // Desactiva la carga cuando se completa la solicitud
      });
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de eliminar este landing?");
    if (!confirmDelete) {
      return; // Cancelar la operación si el usuario no confirma
    }

    try {
      await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/newsletter/${id}`, {
        method: 'DELETE'
      });
      setLanding(prevLanding => prevLanding.filter(landingItem => landingItem._id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section>
      <div className="max-w-screen-xl mx-auto px-4 mt-8">
        <h1 className="text-3xl font-bold mb-4">Lista de landings</h1>

        {loading ? (
          <div className="flex justify-center items-center mt-8">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
            <p className="ml-4 text-lg text-gray-600">Cargando landings...</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            {landing.length > 0 ? (
              <table className="table-auto w-full border-collapse">
                <thead className="bg-gray-300">
                  <tr>
                    <th className="px-4 py-2">Título</th>
                    {landing.some(landingItem => landingItem.description) && <th className="px-4 py-2">Descripción</th>}
                    <th className="px-1 py-2">Eliminar</th>
                    <th className="px-1 py-2">Editar</th>
                    {landing.some(landingItem => landingItem.keywords) && <th className="px-1 py-2">Ver</th>}
                  </tr>
                </thead>
                <tbody>
                  {landing.map(landingItem => (
                    <tr key={landingItem._id} className="border-t">
                      <td className="px-4 py-2">{landingItem.title || 'Borrador'}</td>
                      {landingItem.description && <td className="px-4 py-2">{landingItem.description}</td>}
                      <td className="px-4 py-2">
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          onClick={() => handleDelete(landingItem._id)}
                        >
                          Eliminar
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <Link
                          to={`/newsletteredit/${landingItem._id}`}  // Enlace para editar usando landingItem._id
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                        >
                          Editar
                        </Link>
                      </td>
                      {landingItem.keywords && (
                        <td className="px-4 py-2">
                          <Link
                            to={`/newsletter/${landingItem.keywords.replace(/\s/g, '-')}`}  // Enlace para ver usando keywords
                            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                          >
                            Ver
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center text-gray-600 mt-8">No hay landings disponibles o están incompletos.</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default LandingPageList;
