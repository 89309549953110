const loadVideoBlock = (editor) => {
    editor.BlockManager.add('video-block', {
      label: 'Video Section',
      content: `
        <section class="video-section">
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
                    frameborder="0" allow="autoplay; encrypted-media" 
                    allowfullscreen class="video-embed"></iframe>
          </div>
        </section>
        <style>
          .video-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .video-container {
            max-width: 800px;
            margin: 0 auto;
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
          }
          .video-embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          }
        </style>
      `,
      category: 'General',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadVideoBlock;
  