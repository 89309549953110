import React, { createContext, useContext, useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../api/firebase.config';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // Para almacenar si el usuario es admin
  const [loading, setLoading] = useState(true);

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const userCredential = result.user;
      setUser(userCredential);

      // Ahora, después del inicio de sesión, obtenemos el rol del usuario desde Firestore
      const userDoc = await getDoc(doc(db, "users", userCredential.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.role === 'admin'); // Comprobamos si el rol es 'admin'
      }
    } catch (error) {
      console.error('Error en el inicio de sesión con Google', error);
    }
  };

  const iniciarSesionCorreo = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      console.log('Inicio de sesión exitoso');
      
      // Ahora, después del inicio de sesión, obtenemos el rol del usuario desde Firestore
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.role === 'admin'); // Comprobamos si el rol es 'admin'
      }
    } catch (error) {
      console.error('Error en el inicio de sesión con correo y contraseña', error);
      throw error; // Para manejar el error en el componente que llama
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAdmin(false);
    } catch (error) {
      console.error('Error en el cierre de sesión', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.role === 'admin');
        }
      } else {
        setIsAdmin(false);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, googleSignIn, iniciarSesionCorreo, logOut, isAdmin, loading }}>
      {loading ? <p>Cargando...</p> : children}
    </AuthContext.Provider>
  );
}

export function UserAuth() {
  return useContext(AuthContext);
}
