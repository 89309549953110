const loadButtonBlock = (editor) => {
    editor.BlockManager.add('button-block', {
      label: 'Buttons',
      content: `
        <section class="button-section">
          <div class="button-container">
            <a href="#" class="button primary-button">Botón Primario</a>
            <a href="#" class="button secondary-button">Botón Secundario</a>
          </div>
        </section>
        <style>
          .button-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .button-container {
            display: flex;
            justify-content: center;
            gap: 20px;
          }
          .button {
            padding: 15px 30px;
            font-size: 18px;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
            transition: background-color 0.3s ease, transform 0.3s ease;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
          .primary-button {
            background-color: #007bff;
            color: white;
          }
          .primary-button:hover {
            background-color: #0056b3;
          }
          .secondary-button {
            background-color: #6c757d;
            color: white;
          }
          .secondary-button:hover {
            background-color: #5a6268;
          }
        </style>
      `,
      category: 'Buttons',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadButtonBlock;
  