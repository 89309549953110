const loadSocialBlock = (editor) => {
    editor.BlockManager.add('social-block', {
      label: 'Social Media Share',
      content: `
        <section style="text-align: center; padding: 40px 20px; border-top: 2px solid #e2e8f0;">
          <p style="font-size: 1.2em; color: #4a5568;">Comparte este artículo:</p>
          <div style="margin-top: 20px;">
            <a href="#" style="margin: 0 10px; color: #1DA1F2;"><i class="fa fa-twitter fa-2x"></i></a>
            <a href="#" style="margin: 0 10px; color: #4267B2;"><i class="fa fa-facebook fa-2x"></i></a>
            <a href="#" style="margin: 0 10px; color: #E1306C;"><i class="fa fa-instagram fa-2x"></i></a>
            <a href="#" style="margin: 0 10px; color: #2867B2;"><i class="fa fa-linkedin fa-2x"></i></a>
          </div>
        </section>
      `,
      category: 'Blog',
    });
  };
  
  export default loadSocialBlock;
  