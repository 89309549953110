const loadContentBlock = (editor) => {
    editor.BlockManager.add('content-block', {
      label: 'Article Content',
      content: `
        <section style="padding: 40px 20px; line-height: 1.8; color: #4a5568;">
          <h2 style="font-size: 2em; margin-bottom: 20px; font-weight: 600; color: #2d3748;">Subtítulo del Artículo</h2>
          <p style="margin-bottom: 20px;">Este es el contenido principal del artículo. Aquí puedes escribir varios párrafos que expliquen en detalle el tema que estás abordando.</p>
          <p style="margin-bottom: 20px;">Cada párrafo debe tener un buen espaciado para que sea fácil de leer y esté bien organizado visualmente.</p>
        </section>
      `,
      category: 'Blog',
    });
  };
  
  export default loadContentBlock;
  