const loadHeaderBlock = (editor) => {
    editor.BlockManager.add('header-block', {
      label: 'Article Header',
      content: `
        <header style="text-align: center; padding: 60px 20px; background: #f7fafc; border-bottom: 2px solid #e2e8f0;">
          <h1 style="font-size: 3em; margin-bottom: 15px; font-weight: 700; color: #2d3748;">Título del Artículo</h1>
          <p style="font-size: 1.2em; color: #4a5568;">Autor | Fecha de Publicación</p>
        </header>
      `,
      category: 'Blog',
    });
  };
  
  export default loadHeaderBlock;
  