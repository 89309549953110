const loadTestimonialsBlock = (editor) => {
    editor.BlockManager.add('Testimonials Block', {
      label: 'Testimonials Block',
            content: `
              <section class="testimonials-block" style="padding: 50px 20px; background: #f9f9f9;">
                <div style="text-align: center; margin-bottom: 30px;">
                  <h3 style="font-size: 2em;">What Our Clients Say</h3>
                  <p style="color: #777;">Testimonials from our satisfied customers</p>
                </div>
                <div style="display: flex; justify-content: space-around;">
                  <div style="width: 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                    <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
                    <h4 style="font-size: 1.2em; margin-top: 10px;">- John Doe</h4>
                  </div>
                  <div style="width: 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                    <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
                    <h4 style="font-size: 1.2em; margin-top: 10px;">- Jane Smith</h4>
                  </div>
                  <div style="width: 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                    <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
                    <h4 style="font-size: 1.2em; margin-top: 10px;">- Bob Johnson</h4>
                  </div>
                </div>
              </section>
            `,
     
    });
  };
  
  export default loadTestimonialsBlock;
  