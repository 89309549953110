const loadConclusionBlock = (editor) => {
    editor.BlockManager.add('conclusion-block', {
      label: 'Conclusion & Call to Action',
      content: `
        <section style="padding: 40px 20px; background: #f7fafc; text-align: center; border-top: 2px solid #e2e8f0;">
          <h3 style="font-size: 2em; margin-bottom: 20px; font-weight: 600; color: #2d3748;">Conclusión</h3>
          <p style="margin-bottom: 20px; color: #4a5568;">Resumen final del artículo y conclusiones importantes.</p>
          <a href="#" style="background: #63b3ed; color: white; padding: 10px 20px; border-radius: 5px; text-decoration: none; font-size: 1.1em;">Llamado a la Acción</a>
        </section>
      `,
      category: 'Blog',
    });
  };
  
  export default loadConclusionBlock;
  