import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LandingPageList() {
  const [landing, setLanding] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para indicar si está cargando
  const [timeoutReached, setTimeoutReached] = useState(false); // Estado para manejar el tiempo de espera

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeoutReached(true);
    }, 30000); // 15 segundos de espera

    fetch('https://modelo-de-negocios-backend-180g.onrender.com/landing-page')
      .then(response => {
        clearTimeout(timeout); // Limpiar el timeout si la respuesta llega a tiempo
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setIsLoading(false); // Detener la carga cuando los datos son recibidos
        if (Array.isArray(data)) {
          const validLanding = data.filter(item => item.title && item.description && item.keywords);
          setLanding(validLanding);
        } else {
          console.error("Unexpected data format:", data);
          setLanding([]);
        }
      })
      .catch(error => {
        clearTimeout(timeout);
        console.log("Error fetching landing pages: ", error);
        setLanding([]);
        setIsLoading(false); // Detener la carga incluso en caso de error
      });
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de eliminar este landing?");
    if (!confirmDelete) {
      return; // Cancelar la operación si el usuario no confirma
    }

    try {
      await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/landingView/${id}`, {
        method: 'DELETE'
      });
      setLanding(prevLanding => prevLanding.filter(landingItem => landingItem._id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section>
      <div className="max-w-screen-xl mx-auto px-4 mt-8">
        <h1 className="text-3xl font-bold mb-4">Lista de landings</h1>
        {isLoading && !timeoutReached && (
          <p className="text-xl text-center text-gray-600 m-3">Cargando landings, por favor espera...</p>
        )}
        {!isLoading && landing.length === 0 && (
          <p className="text-xl text-center text-gray-600 m-10">No hay landings disponibles.</p>
        )}
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse">
            <thead className="bg-gray-300">
              <tr>
                <th className="px-4 py-2">Título</th>
                <th className="px-4 py-2">Descripción</th>
                <th className="px-1 py-2">Eliminar</th>
                <th className="px-1 py-2">Editar</th>
                <th className="px-1 py-2">Ver</th>
              </tr>
            </thead>
            <tbody>
              {landing.map(landingItem => (
                <tr key={landingItem._id} className="border-t">
                  <td className="px-4 py-2">{landingItem.title || "Título no disponible"}</td>
                  <td className="px-4 py-2">{landingItem.description || "Descripción no disponible"}</td>
                  <td className="px-4 py-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(landingItem._id)}
                    >
                      Eliminar
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <Link
                      to={`/LandingEdit/${landingItem._id}`}  // Enlace para editar usando landingItem._id
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    >
                      Editar
                    </Link>
                  </td>
                  <td className="px-4 py-2">
                    <Link
                      to={`/landingView/${landingItem.keywords ? landingItem.keywords.replace(/\s/g, '-') : landingItem._id}`}
                      className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    >
                      Ver
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default LandingPageList;
