const loadHeaderBlock = (editor) => {
    editor.BlockManager.add('Banner-block', {
      label: 'Banner Block',
      content: `
        <section class="banner-block" style="position: relative; text-align: center; padding: 100px 20px; background: url('https://via.placeholder.com/1500x500') no-repeat center center; background-size: cover;">
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;">
            <h2 style="font-size: 2em;">Achieve Your Dreams</h2>
            <p style="font-size: 1.2em;">Join us and make it happen</p>
            <button style="padding: 10px 20px; background: #28a745; color: white; border: none; border-radius: 5px; cursor: pointer;">Get Started</button>
          </div>
        </section>
      `,
 
    });
  };
  
  export default loadHeaderBlock;
  