const loadCtaBlock = (editor) => {
    editor.BlockManager.add('Cta Block', {
      label: 'Call to Action Block',
      content: `
        <section class="cta-block" style="padding: 50px 20px; background: #28a745; color: white; text-align: center;">
          <h3 style="font-size: 2em; margin-bottom: 20px;">Ready to get started?</h3>
          <p style="font-size: 1.2em;">Join us today and start achieving your dreams</p>
          <button style="padding: 10px 20px; background: #fff; color: #28a745; border: none; border-radius: 5px; cursor: pointer; margin-top: 20px;">Sign Up Now</button>
        </section>
      `,
     
    });
  };
  
  export default loadCtaBlock;
  