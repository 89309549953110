import DOMPurify from 'dompurify';

const loadCodeBlock = (editor) => {
  editor.BlockManager.add('code-block', {
    label: 'Iframe',
    content: `
      <div class="code-block" style="text-align: center;">
        Insert your custom HTML code here
      </div>
    `,
    category: 'General',
    attributes: { class: 'gjs-block-code' },
  });

  editor.DomComponents.addType('code-block', {
    model: {
      defaults: {
        tagName: 'div',
        droppable: true,
        editable: true,
        traits: [
          {
            type: 'textarea',
            label: 'HTML',
            name: 'content',
            changeProp: 1,
            placeholder: 'Insert your custom HTML code here...'
          }
        ],
        style: {
          'text-align': 'center',
        },
      },
      init() {
        this.on('change:content', this.handleChangeContent);
      },
      handleChangeContent() {
        // Sanitizar el HTML antes de mostrarlo en la vista
        const cleanHtml = DOMPurify.sanitize(this.get('content'), { USE_PROFILES: { html: true } });
        this.view.el.innerHTML = cleanHtml;
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        const htmlCode = prompt('Insert your HTML code:', this.model.get('content') || '');
        if (htmlCode) {
          // Sanitizar el HTML antes de guardarlo en el modelo
          const cleanHtml = DOMPurify.sanitize(htmlCode, { USE_PROFILES: { html: true } });
          this.model.set('content', cleanHtml);
        }
      },
    },
  });
};

export default loadCodeBlock;
