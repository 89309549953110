const loadFooterBlock = (editor) => {
    editor.BlockManager.add('Footer Block', {
      label: 'Footer Block',
      content: `
        <footer class="footer-block" style="padding: 20px; background: #333; color: white; text-align: center;">
          <p>&copy; 2023 Your Company. All rights reserved.</p>
          <p style="font-size: 0.9em;">Follow us on:</p>
          <div style="margin-top: 10px;">
            <a href="#" style="color: white; margin-right: 10px;"><i class="fa fa-facebook"></i></a>
            <a href="#" style="color: white; margin-right: 10px;"><i class="fa fa-twitter"></i></a>
            <a href="#" style="color: white; margin-right: 10px;"><i class="fa fa-instagram"></i></a>
          </div>
        </footer>
      `,
     
    });
  };
  
  export default loadFooterBlock;
  