import React, { useState, useEffect } from 'react';

import Dashboard from '../components/Admin/Dashboard';
import Navbar from '../components/Admin/NavBar';


export function Administrator() {




  return (
    <>
      <Navbar/>

      <Dashboard/>

    </>
  );
}


