const loadAboutBlock = (editor) => {
  editor.BlockManager.add('about-block', {
    label: 'About Us',
    content: `
      <section class="about-section">
        <div class="about-container">
          <h2 class="about-title">Acerca de Nosotros</h2>
          <p class="about-description">Somos una empresa dedicada a [tu misión/visión], comprometidos con [valores/principios]. Nuestro objetivo es [objetivo principal].</p>
        </div>
      </section>
      <style>
        .about-section {
          background-color: #fff;
          padding: 60px 20px;
          text-align: center;
          font-family: 'Arial', sans-serif;
        }
        .about-container {
          max-width: 800px;
          margin: 0 auto;
        }
        .about-title {
          font-size: 36px;
          margin-bottom: 20px;
          font-weight: 700;
          color: #333;
        }
        .about-description {
          font-size: 18px;
          color: #666;
          line-height: 1.6;
        }
      </style>
    `,
    category: 'Sections',
    attributes: { class: 'gjs-block-section' },
  });
};

export default loadAboutBlock;
