import { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import display from "../assets/Display.jpg";

export function LoginPage() {
  const navigate = useNavigate();
  const { user, iniciarSesionCorreo, isAdmin, loading } = UserAuth();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Maneja el inicio de sesión con correo y contraseña
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await iniciarSesionCorreo(email, password);
    } catch (error) {
      console.error("Error al iniciar sesión con correo y contraseña", error);
    }
  };

  useEffect(() => {
    if (loading) return; // Espera a que se cargue el estado
  
    if (user) {
      if (isAdmin) {
        navigate("/administrator"); // Redirige a admin si es admin
      } else {
        // alert("No tienes permisos para acceder a esta sección");
      }
    }
  }, [user, isAdmin, loading, navigate]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-blue-100 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <img src={display} alt="" className="w-full h-full object-cover" />
      </div>

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
        <div className="w-full h-100">
          <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">Entra a tu cuenta</h1>
          
          {/* Formulario de inicio de sesión con correo y contraseña */}
          <form onSubmit={handleSubmit}>
            <div>
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full border p-2 mt-2"
              />
            </div>
            <div>
              <label>Contraseña</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full border p-2 mt-2"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 mt-4 rounded"
            >
              Iniciar sesión
            </button>
          </form>

        </div>
      </div>
    </section>
  );
}
