const loadPrivacyBlock = (editor) => {
    editor.BlockManager.add('privacy-block', {
      label: 'Privacy Policy',
      content: `
        <section class="privacy-section">
          <div class="privacy-container">
            <h2 class="privacy-title">Política de Privacidad</h2>
            <p class="privacy-description">En [Tu Empresa], valoramos tu privacidad y nos comprometemos a proteger tu información personal. Esta política de privacidad explica cómo recogemos, utilizamos y compartimos tu información.</p>
          </div>
        </section>
        <style>
          .privacy-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .privacy-container {
            max-width: 800px;
            margin: 0 auto;
          }
          .privacy-title {
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #333;
          }
          .privacy-description {
            font-size: 18px;
            color: #666;
            line-height: 1.6;
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadPrivacyBlock;
  