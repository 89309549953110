const loadSectionBlock = (editor) => {
    editor.BlockManager.add('Section Block', {
      label: '<b>Section</b>',
      content: '<section><h1>Put here your title</h1></section>',
      category: 'General',
      attributes: { class: 'gjs-block-section' }
     
    });
  };
  
  export default loadSectionBlock;
  