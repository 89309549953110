import React, { useState } from 'react';

import MetadataForm from '../components/Newsletter/MetadataForm';




function NewsletterPageMeta() {
  

  return (
    <>

 

    <MetadataForm/>



    </>
  );
}

export default NewsletterPageMeta;
