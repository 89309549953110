const loadPricingBlock = (editor) => {
    editor.BlockManager.add('pricing-block', {
      label: 'Pricing/Menu',
      content: `
        <section class="pricing-section">
          <div class="pricing-container">
            <h2 class="pricing-title">Lista de Precios</h2>
            <ul class="pricing-list">
              <li class="pricing-item">
                <span class="pricing-name">Producto 1</span>
                <span class="pricing-price">$49.99</span>
              </li>
              <li class="pricing-item">
                <span class="pricing-name">Producto 2</span>
                <span class="pricing-price">$59.99</span>
              </li>
              <li class="pricing-item">
                <span class="pricing-name">Producto 3</span>
                <span class="pricing-price">$69.99</span>
              </li>
            </ul>
          </div>
        </section>
        <style>
          .pricing-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .pricing-container {
            max-width: 800px;
            margin: 0 auto;
          }
          .pricing-title {
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #333;
          }
          .pricing-list {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .pricing-item {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ddd;
            font-size: 18px;
          }
          .pricing-name {
            font-weight: 600;
            color: #555;
          }
          .pricing-price {
            font-weight: 600;
            color: #333;
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadPricingBlock;
  