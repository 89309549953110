const loadContentBlock = (editor) => {
    editor.BlockManager.add('content-block', {
      label: 'Content Section',
      content: `
        <section class="content-section">
          <div class="content-container">
            <h2 class="content-title">Título de la Sección de Contenido</h2>
            <p class="content-description">Este es un párrafo de contenido que puedes utilizar para describir tu producto o servicio. Puedes agregar más texto o imágenes según sea necesario.</p>
          </div>
        </section>
        <style>
          .content-section {
            background-color: #fff;
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .content-container {
            max-width: 800px;
            margin: 0 auto;
          }
          .content-title {
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #333;
          }
          .content-description {
            font-size: 18px;
            color: #666;
            line-height: 1.6;
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadContentBlock;
  