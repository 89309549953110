const loadPayPalBlock = (editor) => {
    editor.BlockManager.add('paypal-button', {
      label: 'PayPal Button',
      content: `
        <div class="paypal-button-container">
          <a href="#" class="paypal-button">Comprar con PayPal</a>
        </div>
        <style>
          .paypal-button-container {
            text-align: center;
            margin: 20px 0;
          }
          .paypal-button {
            background-color: #0070ba;
            color: #fff;
            padding: 14px 28px;
            font-size: 16px;
            text-decoration: none;
            border-radius: 30px;
            display: inline-block;
            font-weight: bold;
            transition: background-color 0.3s ease, transform 0.2s ease;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          }
          .paypal-button:hover {
            background-color: #005ea6;
            transform: translateY(-2px);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
          }
        </style>
      `,
      category: 'General',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadPayPalBlock;
  