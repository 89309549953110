const loadImageBlock = (editor) => {
    editor.BlockManager.add('image-block', {
      label: 'Article Image',
      content: `
        <figure style="text-align: center; margin: 40px 0;">
          <img src="https://via.placeholder.com/800x400" alt="Descripción de la Imagen" style="max-width: 100%; height: auto; border-radius: 8px;">
          <figcaption style="font-size: 1em; color: #718096; margin-top: 10px;">Pie de foto que explica la imagen mostrada</figcaption>
        </figure>
      `,
      category: 'Blog',
    });
  };
  
  export default loadImageBlock;
  