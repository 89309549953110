import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);  // Estado para manejar la carga
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false); // Desactiva la carga después de 15 segundos
    }, 15000);

    fetch('https://modelo-de-negocios-backend-180g.onrender.com')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          setBlogs(data);
        } else {
          console.error("Unexpected data format:", data);
          setBlogs([]); // Default to empty array if data format is unexpected
        }
      })
      .catch(error => {
        console.log("Error Blogs: ", error);
        setBlogs([]); // Set to empty array on error
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);  // Desactiva la carga cuando se completa la solicitud
      });
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de eliminar este blog?");
    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blog/${id}`, {
        method: 'DELETE'
      });
      setBlogs(prevBlogs => prevBlogs.filter(blog => blog._id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };

  // Filtra los blogs según el término de búsqueda
  const filteredBlogs = Array.isArray(blogs) ? blogs.filter(blog =>
    blog.title?.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  return (
    <>
      <section>
        <div className="max-w-screen-xl mx-auto px-4 mt-8">
          <h1 className="text-3xl font-bold mb-4">Lista de Blogs</h1>

          {/* Campo de búsqueda */}
          <input
            type="text"
            placeholder="Buscar blogs..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="mb-4 px-4 py-2 border rounded-md"
          />

          {loading ? (
            <div className="flex justify-center items-center mt-8">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
              <p className="ml-4 text-lg text-gray-600">Cargando blogs...</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              {filteredBlogs.length > 0 ? (
                <table className="table-auto w-full border-collapse">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className="px-4 py-2">Título</th>
                      <th className="px-4 py-2">Descripción</th>
                      <th className="px-1 py-2">Eliminar</th>
                      <th className="px-1 py-2">Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.slice(-10).map(blog => (
                      <tr key={blog._id} className="border-t">
                        <td className="px-4 py-2">{blog.title || "Título no disponible"}</td>
                        <td className="px-4 py-2">{blog.description || "Descripción no disponible"}</td>
                        <td className="px-4 py-2">
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => handleDelete(blog._id)}>
                            Eliminar
                          </button>
                        </td>
                        <td className="px-4 py-2">
                          <Link to={`/edit/${encodeURIComponent(blog.keywords.replace(/\s/g, '-'))}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2">
                            Editar
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center text-gray-600 mt-8">No hay blogs disponibles o están incompletos.</p>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default BlogList;
