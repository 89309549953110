import React, { useState } from 'react';
import NewLandingPage from '../components/LandingPage/MetadataForm';
import GrapesEditor from '../components/LandingPage/GrapesEditor';
import GrapesEditor2 from '../components/LandingPage/GrapesEditor2';




function New() {
  

  return (
    <>

 

    <GrapesEditor/>



    </>
  );
}

export default New;
