const loadHeaderBlock = (editor) => {
    editor.BlockManager.add('header-block', {
      label: 'Landing Page Header',
      content: `
        <header class="landing-header">
          <div class="header-container">
            <h1 class="header-title">Bienvenido a [Tu Producto]</h1>
            <p class="header-subtitle">La mejor solución para [Tu Necesidad]</p>
            <a href="#cta" class="header-cta">Empieza Ahora</a>
          </div>
        </header>
        <style>
          .landing-header {
            padding: 100px 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 70vh;
            font-family: 'Arial', sans-serif;
          }
          .header-container {
            max-width: 800px;
            margin: 0 auto;
          }
          .header-title {
            font-size: 48px;
            margin-bottom: 20px;
            line-height: 1.2;
            font-weight: 700;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
          }
          .header-subtitle {
            font-size: 24px;
            margin-bottom: 30px;
            line-height: 1.5;
            font-weight: 400;
          }
          .header-cta {
            background-color: #ffc439;
            color: #111;
            padding: 15px 30px;
            font-size: 18px;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
            transition: background-color 0.3s ease, transform 0.3s ease;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          }
          .header-cta:hover {
            background-color: #ffb000;
            transform: translateY(-3px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
          }
        </style>
      `,
      category: 'Sections',
      attributes: { class: 'gjs-block-section' },
    });
  };
  
  export default loadHeaderBlock;
  