const loadImageBlock = (editor) => {
    editor.BlockManager.add('Image Block', {
      label: 'Image',
      content: {
          type: 'image',
          activeOnRender: 1
      },
      category: 'General',
      attributes: { class: 'gjs-fonts gjs-f-image' }
     
    });
  };
  
  export default loadImageBlock;
  