const loadBasicBlock = (editor) => {
    editor.BlockManager.add('Basic Block', {
      label: 'Block Label', 
      content: '<div class="your-class">Content here</div>', 
      category: 'General',
      attributes: { class: 'gjs-block-section' }, 
     
    });
  };
  
  export default loadBasicBlock;
  